// data/problems.js

export const PROBLEMS = [
	{
		id: 'problem-organizacao',
		description: 'Dificuldade em organizar treinos e dieta',
	},
	{
		id: 'problem-resultados',
		description: 'Falta de resultados consistentes',
	},
	{
		id: 'problem-motivacao',
		description: 'Perda de motivação por não ver progresso',
	},
	{
		id: 'problem-confusao',
		description: 'Confusão com excesso de informações online',
	},
]
