// data/faqs.js

export const FAQS = [
	{
		id: 'faq-gratuito',
		question: 'O app é gratuito?',
		answer: 'Sim, você pode começar gratuitamente com funcionalidades básicas.',
	},
	{
		id: 'faq-offline',
		question: 'O app funciona offline?',
		answer: 'Não, como é aplicação web não existe possibilidade de offline ainda.',
	},
	{
		id: 'faq-suporte-iniciantes',
		question: 'Há suporte para iniciantes?',
		answer: 'Com certeza! Nosso app é ideal para qualquer nível de experiência.',
	},
	{
		id: 'faq-personalizar-plano',
		question: 'Posso personalizar o plano?',
		answer: 'Sim, treinos e dietas são ajustáveis às suas necessidades.',
	},
]
