// src/app/[locale]/page.tsx
'use client'

import { ClientHome } from '@/components'
import { useSession } from 'next-auth/react'
import { useRouter } from 'next/navigation'
import React, { useEffect } from 'react'
import LandingPage from './lander/page'

export default function Home() {
	const { data: session, status } = useSession()
	const router = useRouter()

	useEffect(() => {
		if (status === 'authenticated' && session?.user?.role) {
			const userRole = session.user.role
			if (userRole === 'ADMIN' || userRole === 'COACH') {
				router.replace('/dashboard')
			}
		}
	}, [session, status, router])

	if (status === 'loading') {
		// You can render a loading indicator while checking the session
		return <div>Loading...</div>
	}

	if (status === 'unauthenticated') {
		// User is not authenticated, show the LandingPage
		return <LandingPage />
	}

	// User is authenticated
	if (status === 'authenticated' && session?.user?.role) {
		const userRole = session.user.role

		if (userRole === 'CLIENT') {
			// Renderiza o ClientHome
			return <ClientHome />
		}

		if (userRole === 'ADMIN' || userRole === 'COACH') {
			// This case should have been handled in useEffect, but adding for completeness
			router.replace('/dashboard')
			return null // or a loading indicator
		}
	}

	// In case the user has a role that is not handled or session.user.role is undefined
	return <div>Redirecting...</div>
}
