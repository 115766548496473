'use client'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '@/components/ui/accordion'
import { Button } from '@/components/ui/button'
import { Card, CardContent, CardTitle } from '@/components/ui/card'
import { ChevronRight } from 'lucide-react'
import Image from 'next/image'
import React from 'react'

// Importando os dados refatorados
import { BENEFITS, FAQS, PROBLEMS } from '@/data'

const LandingPage = () => {
	return (
		<div className='min-h-screen bg-white text-slate-900'>
			{/* Hero Section */}
			<section className='pt-24 pb-16  md:pb-24'>
				<div className='container mx-auto px-4'>
					<div className='flex flex-col md:flex-row items-center'>
						<div className='md:w-1/2'>
							<h1 className='text-4xl md:text-6xl font-bold leading-tight mb-6'>
								Alcance o corpo dos seus sonhos com treinos e nutrição na palma da sua mão
							</h1>
							<p className='text-xl text-gray-600 mb-8'>Um app feito para simplificar sua jornada fitness</p>
							<Button size='lg' className='text-lg w-64'>
								Cadastre-se Gratuitamente
							</Button>
						</div>
						<div className='md:w-1/2 mt-8 md:mt-0 relative h-[400px]'>
							<Image src='/assets/mobile.webp' alt='Hero' fill className='rounded-lg shadow-xl object-cover' priority />
						</div>
					</div>
				</div>
			</section>

			{/* Seção 1: Problema */}
			<section className='py-16 bg-gray-50'>
				<div className='container mx-auto px-4'>
					<h2 className='text-3xl md:text-4xl font-bold text-center mb-12'>
						A falta de planejamento está sabotando seu progresso fitness?
					</h2>
					<div className='grid md:grid-cols-2 lg:grid-cols-4 gap-8'>
						{PROBLEMS.map((problem) => (
							<div key={problem.id} className='bg-white p-6 rounded-lg shadow-md'>
								<p className='text-lg'>{problem.description}</p>
							</div>
						))}
					</div>
				</div>
			</section>

			{/* Seção 2: Solução */}
			<section className='py-16'>
				<div className='container mx-auto px-4'>
					<div className='flex flex-col md:flex-row items-center'>
						<div className='md:w-1/2'>
							<h2 className='text-3xl md:text-4xl font-bold mb-6'>
								Simplifique sua rotina fitness com nosso app completo
							</h2>
							<p className='text-lg text-gray-600 mb-8'>
								Nosso app combina treinos personalizados, planos nutricionais e suporte contínuo em uma única
								plataforma. Perfeito para quem deseja resultados de forma prática e eficiente.
							</p>
						</div>
						<div className='md:w-1/2 mt-8 md:mt-0 relative h-[400px]'>
							<Image
								src='/assets/bodybuilder.webp'
								alt='App Interface'
								fill
								className='rounded-lg shadow-xl object-cover'
							/>
						</div>
					</div>
				</div>
			</section>

			{/* Seção 3: Benefícios */}
			<section className='py-16 bg-gray-50'>
				<div className='container mx-auto px-4'>
					<h2 className='text-3xl md:text-4xl font-bold text-center mb-12'>Benefícios</h2>
					<div className='grid md:grid-cols-3 gap-8'>
						{BENEFITS.map((benefit) => (
							<Card key={benefit.id}>
								<CardContent className='pt-6'>
									<div className='text-center'>
										{benefit.icon}
										<CardTitle className='mb-4'>{benefit.title}</CardTitle>
										<p className='text-gray-600'>{benefit.description}</p>
									</div>
								</CardContent>
							</Card>
						))}
					</div>
				</div>
			</section>

			{/* Seção 4: Como Funciona */}
			<section className='py-16'>
				<div className='container mx-auto px-4'>
					<h2 className='text-3xl md:text-4xl font-bold text-center mb-12'>Como Funciona</h2>
					<div className='space-y-8'>
						{[
							{
								id: 'step-1',
								step: 1,
								title: 'Acesse o App',
								description: 'entre em www.app.com',
							},
							{
								id: 'step-2',
								step: 2,
								title: 'Configure seu Perfil',
								description: 'Adicione seus objetivos, rotina e preferências alimentares.',
							},
							{
								id: 'step-3',
								step: 3,
								title: 'Receba seu Plano',
								description: 'Treinos e nutrição e protocolos adaptados à sua jornada.',
							},
							{
								id: 'step-4',
								step: 4,
								title: 'Monitore o Progresso',
								description: 'Acompanhe métricas de desempenho e ajuste conforme necessário.',
							},
							{
								id: 'step-5',
								step: 5,
								title: 'Evolua Continuamente',
								description: 'Conte com suporte e atualizações para manter sua evolução.',
							},
						].map((step) => (
							<div key={step.id} className='flex items-start'>
								<div className='flex-shrink-0 w-12 h-12 rounded-full bg-primary text-white flex items-center justify-center font-bold'>
									{step.step}
								</div>
								<div className='ml-6'>
									<h3 className='text-xl font-bold mb-2'>{step.title}</h3>
									<p className='text-gray-600'>{step.description}</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</section>

			{/* Seção 5: FAQ */}
			<section className='py-16 bg-gray-50'>
				<div className='container mx-auto px-4'>
					<h2 className='text-3xl md:text-4xl font-bold text-center mb-12'>Perguntas Frequentes</h2>
					<Accordion type='single' collapsible className='max-w-2xl mx-auto'>
						{FAQS.map((faq) => (
							<AccordionItem key={faq.id} value={`item-${faq.id}`}>
								<AccordionTrigger>{faq.question}</AccordionTrigger>
								<AccordionContent>{faq.answer}</AccordionContent>
							</AccordionItem>
						))}
					</Accordion>
				</div>
			</section>

			{/* Seção 6: CTA Final */}
			<section className='py-16'>
				<div className='container mx-auto px-4 text-center'>
					<h2 className='text-3xl md:text-4xl font-bold mb-6'>Está pronto para transformar sua rotina fitness?</h2>
					<p className='text-xl text-gray-600 mb-8 max-w-2xl mx-auto'>
						Junte-se a uma comunidade de pessoas transformando suas vidas com praticidade e resultados reais.
					</p>
					<Button size='lg' className='text-lg w-64'>
						Quero Começar Agora <ChevronRight className='ml-2 h-5 w-5' />
					</Button>
					<div className='mt-12 relative h-[400px]'>
						<Image src='/assets/fitness.webp' alt='Inspirational' fill className='rounded-lg shadow-xl object-cover' />
					</div>
				</div>
			</section>
		</div>
	)
}

export default LandingPage
