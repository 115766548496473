// data/benefits.js
import { Apple, Dumbbell, LineChart } from 'lucide-react'
const BENEFITS = [
	{
		id: 'benefit-cardio-forca',
		icon: <Dumbbell className='h-12 w-12 mb-4' />,
		title: 'Cardio e Força Personalizados',
		description: 'Receba treinos adaptados ao seu objetivo e nível de condicionamento físico.',
	},
	{
		id: 'benefit-nutricao',
		icon: <Apple className='h-12 w-12 mb-4' />,
		title: 'Nutrição Simplificada',
		description: 'Planeje suas refeições com sugestões fáceis e balanceadas.',
	},
	{
		id: 'benefit-resultados',
		icon: <LineChart className='h-12 w-12 mb-4' />,
		title: 'Resultados Mensuráveis',
		description: 'Acompanhe seu progresso e ajuste o plano para resultados consistentes.',
	},
]

export { BENEFITS }
